import React from "react"
import { Link } from "gatsby"

const MobilePages = () => {
  return (
    <div className="mobile-pages-main">
      <div className="text-center">
        <Link to="/"><p className="d-inline-block p-3 m-0"><span className="text-dark">Recent Posts</span></p></Link>
        <Link to="/about"><p className="d-inline-block p-3 m-0"><span className="text-dark">About</span></p></Link>
        <Link to="/archive"><p className="d-inline-block p-3 m-0"><span className="text-dark">Archive</span></p></Link>
      </div>
    </div>
  )
}

export default MobilePages


