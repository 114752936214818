import React from "react"

import "./header.css"
import me from "../../images/me.jpg"

const MobileBio = (props) => {

  return (
    <div className="mobile-bio-main">
      <img src={me} className="ml-4 mt-2" style={{ maxWidth: `75px`, maxHeight: `75px`, borderRadius: `6px`, boxShadow: `1px 1px 3px` }} alt="author-pic" />
      <div>
        <h4>{props.author}</h4>
        <h5 className="text-muted">{props.tagline}</h5>
      </div>
    </div>
  )
}

export default MobileBio
